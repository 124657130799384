import { colors, scrollbarWidth } from "@/styles/global.styles";
import { filterContacts } from "@/utils/contacts/contactUtils";
import { atoms } from "@/utils/helpers/atoms";
import { contactNameRegexPattern } from "@/utils/helpers/Utils";
import { useContacts } from "@/utils/hooks/useContacts";
import { useAtom, useAtomValue } from "jotai";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useBoolean } from "usehooks-ts";
import {
  DividerLetter,
  DividerLine,
} from "../../pages/contactListScreen/ContactListScreen.style";
import WebGwContact from "../../utils/helpers/WebGwContact";
import Checkbox from "../shared/Checkbox";
import {
  editListItemStyle,
  editListItemStyleDisabled,
  ListContainer,
} from "../shared/ListItem.style";
import NoResultFound from "../shared/NoResultFound";
import SvgGraphic from "../shared/SvgGraphic";
import AlphabeticalScrollBar from "./AlphabeticalScrollBar";
import ContactCard from "./ContactCard";

export type ContactSelectHandler = (searchQuery: string | WebGwContact) => void;

const DEFAULT_IGNORE_CONTACTS: WebGwContact[] = [];

export default function ContactsList({
  searchQuery,
  contactSelectedHandler,
  ignoreContacts = DEFAULT_IGNORE_CONTACTS,
  isContactPicker,
  preSelectedContacts,
  onSelectContact,
  onUnSelectContact,
  fromConversationScreen = false,
  disableNoPhoneContacts = true,
}: {
  searchQuery: string;
  contactSelectedHandler?: ContactSelectHandler;
  ignoreContacts?: WebGwContact[];
  isContactPicker?: boolean;
  preSelectedContacts?: WebGwContact[];
  onSelectContact?: (contact: WebGwContact) => void;
  onUnSelectContact?: (contact: WebGwContact) => void;
  fromConversationScreen?: boolean;
  disableNoPhoneContacts?: boolean;
}) {
  const [_, contactFilterFn] = useAtomValue(atoms.contacts.contactFilter);
  const unfilteredContacts = useContacts();
  const contacts = contactFilterFn(unfilteredContacts);
  const [selectedLetter, setSelectedLetter] = useState("");

  const [displayContactId, setDisplayContactId] = useAtom(
    atoms.contacts.displayContactId
  );

  const contactsToDisplay = filterContacts(
    searchQuery,
    contacts,
    ignoreContacts
  );

  const onContactCardSelectHandler = (contact) => {
    if (
      preSelectedContacts &&
      onUnSelectContact &&
      onSelectContact &&
      contact
    ) {
      const wasSelected = preSelectedContacts.some(
        (current) => current.id === contact.id
      );

      if (wasSelected) {
        onUnSelectContact(contact);
      } else {
        onSelectContact(contact);
      }
    }

    contactSelectedHandler?.(contact);
  };

  const dividerLetterRefs = useRef({});
  const [dividerLetterRefsState, setDividerLetterRefsState] = useState({});

  useLayoutEffect(() => {
    if (Object.keys(dividerLetterRefs.current).length > 0) {
      setDividerLetterRefsState(dividerLetterRefs.current);
    }
  }, [contactsToDisplay]);

  useEffect(() => {
    const container = document.querySelector(".list-container");
    if (!container) return;

    const handleScroll = () => {
      if (!selectedLetter || !dividerLetterRefsState[selectedLetter]) return;

      const containerRect = container.getBoundingClientRect();

      // Find the first visible letter if the selected one is out of view
      let newVisibleLetter = "";

      for (const [letter, element] of Object.entries(dividerLetterRefsState)) {
        const htmlElement = element as HTMLElement | null;
        if (!htmlElement) continue;

        const rect = htmlElement.getBoundingClientRect();
        const isVisible =
          rect.bottom > containerRect.top && rect.top < containerRect.bottom;

        if (isVisible) {
          newVisibleLetter = letter;
          break;
        }
      }

      if (newVisibleLetter && newVisibleLetter !== selectedLetter) {
        setSelectedLetter(newVisibleLetter);
      }
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [dividerLetterRefsState, selectedLetter]);

  return contactsToDisplay.length > 0 ? (
    <div
      css={{
        width: "100%",
        height: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <AlphabeticalScrollBarListContainer
        dividerLetterRefs={dividerLetterRefsState}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
      >
        {contactsToDisplay.map(([contact, searchIndices], index) => {
          if (!contact.id) return null;
          const name = contact.getName();
          const isHashSection =
            !name || name.trim() === "" || contactNameRegexPattern.test(name);
          const dividerLetter = isHashSection ? "#" : name[0].toUpperCase();

          const lastContact = contactsToDisplay.at(index - 1)?.[0];
          const newDivision =
            index === 0 ||
            (!isHashSection &&
              lastContact?.getName()?.[0]?.toUpperCase() !== dividerLetter);

          const disabled =
            disableNoPhoneContacts && !contact.getMainPhoneNumber();

          return (
            <Fragment key={contact.id}>
              {newDivision && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "15px",
                  }}
                >
                  <DividerLetter
                    ref={(elem) => {
                      if (elem) dividerLetterRefs.current[dividerLetter] = elem;
                      else delete dividerLetterRefs.current[dividerLetter];
                    }}
                  >
                    {dividerLetter}
                  </DividerLetter>
                  <DividerLine />
                </div>
              )}
              <li
                css={[
                  editListItemStyle,
                  disabled && editListItemStyleDisabled,
                  {
                    backgroundColor:
                      displayContactId === contact.id
                        ? colors.secondaryBackground
                        : "inherit",
                  },
                ]}
              >
                {isContactPicker && (
                  <Checkbox
                    disabled={disabled}
                    checked={
                      preSelectedContacts?.some(
                        (current) => current.id === contact.id
                      ) ?? false
                    }
                    onChange={() =>
                      !disabled && onContactCardSelectHandler(contact)
                    }
                    css={{ marginRight: "1em" }}
                  />
                )}
                <ContactCard
                  disabled={disabled}
                  contact={contact}
                  searchIndices={searchIndices}
                  searchQueryLength={searchQuery.length}
                  onClick={
                    !disabled
                      ? () => {
                          onContactCardSelectHandler(contact);
                          setDisplayContactId(contact.id);
                        }
                      : undefined
                  }
                />
              </li>
            </Fragment>
          );
        })}
      </AlphabeticalScrollBarListContainer>
    </div>
  ) : !fromConversationScreen || contactsToDisplay.length < 1 ? (
    <NoResultFound
      placeHolder="Enter a contact name or number to start a new conversation"
      searchQuery={searchQuery}
    />
  ) : (
    <SvgGraphic src={"assets/Chat_Graphic.svg"} />
  );
}

function AlphabeticalScrollBarListContainer({
  children,
  dividerLetterRefs,
  selectedLetter,
  setSelectedLetter,
}) {
  const {
    value: showAlphabeticalScrollBar,
    setTrue: setShowAlphabeticalScrollBar,
    setFalse: setHideAlphabeticalScrollBar,
  } = useBoolean(false);

  // Detect scrollbar presence across browsers (Firefox & Chrome)
  useEffect(() => {
    const container = document.querySelector(".list-container");

    const checkScrollBar = () => {
      if (container) {
        const hasScrollbar = container.scrollHeight > container.clientHeight;
        if (hasScrollbar) {
          setShowAlphabeticalScrollBar();
        } else {
          setHideAlphabeticalScrollBar();
        }
      }
    };

    const observer = new ResizeObserver(checkScrollBar);
    const mutationObserver = new MutationObserver(checkScrollBar);
    if (container) {
      observer.observe(container);
      mutationObserver.observe(container, { childList: true, subtree: true });
    }

    checkScrollBar();

    return () => {
      observer.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return (
    <>
      <ListContainer
        className="list-container"
        style={{
          paddingRight: showAlphabeticalScrollBar ? "2.25em" : "0",
          transition: `padding-right ease 0.35s`,
        }}
      >
        {children}
      </ListContainer>
      <div
        css={{
          position: "absolute",
          right: `calc(${scrollbarWidth} + 1.25em)`,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          transition: "opacity 0.35s ease",
          transform: "translateY(0.75em)",
        }}
        style={{
          opacity: showAlphabeticalScrollBar ? 1 : 0,
        }}
      >
        <AlphabeticalScrollBar
          dividerLetterRefs={dividerLetterRefs}
          selectedLetter={selectedLetter}
          setSelectedLetter={setSelectedLetter}
        />
      </div>
    </>
  );
}
