import { getBotNameFromSip } from "../../..";
import Conversation, { DEFAULT_GROUP_CHAT_NAME } from "../Conversation";

export const getConversationTitle = (
  conversation: Conversation,
  noNameGroupChatPosition?: number
) => {
  const contact = conversation.participants[0];

  let conversationTitle = conversation.getName();

  const isChatbot = !conversation.getIsGroupChat() && contact.isChatbot;

  if (!conversationTitle) {
    if (conversation.getIsGroupChat()) {
      conversationTitle =
        DEFAULT_GROUP_CHAT_NAME +
        ((noNameGroupChatPosition || 0) > 1
          ? " (" + noNameGroupChatPosition + ")"
          : "");
    } else if (isChatbot) {
      conversationTitle = getBotNameFromSip(contact.getMainPhoneNumber());
    }
  }

  conversationTitle ||= "";

  return { conversationTitle, isChatbot };
};
