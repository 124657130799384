import { colors } from "@/styles/global.styles";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { Letter } from "./AlphabeticalScrollBar.style";

export type DividerLetterRecord = Record<string, HTMLElement | undefined>;

type Props = {
  dividerLetterRefs: DividerLetterRecord;
  selectedLetter: string;
  setSelectedLetter: (letter: string) => void;
};

const AlphabeticalScrollBar = ({
  dividerLetterRefs,
  selectedLetter,
  setSelectedLetter,
}: Props) => {
  const [fontSize] = useState(0.7);
  const containerRef = useRef<HTMLDivElement>(null);

  const findClosestLetter = (letter: string) => {
    const dividerLetterKeys = Object.keys(dividerLetterRefs);

    const letterIndex = dividerLetterKeys.indexOf(letter);
    if (letterIndex !== -1) {
      return dividerLetterKeys[letterIndex];
    } else {
      let closestLetter = dividerLetterKeys[0];
      for (let i = 1; i < dividerLetterKeys.length; i++) {
        const prevDiff = Math.abs(
          closestLetter.charCodeAt(0) - letter.charCodeAt(0)
        );
        const currDiff = Math.abs(
          dividerLetterKeys[i].charCodeAt(0) - letter.charCodeAt(0)
        );
        if (currDiff < prevDiff) {
          closestLetter = dividerLetterKeys[i];
        }
      }
      return closestLetter;
    }
  };

  const scrollToSelectedLetter = (letter: string) => {
    const closestLetter = findClosestLetter(letter);
    if (closestLetter && dividerLetterRefs[closestLetter]) {
      dividerLetterRefs[closestLetter]?.scrollIntoView({
        behavior: "smooth",
      });
      setSelectedLetter(closestLetter);
    } else {
      console.log("No closest letter found.");
    }
  };

  const handleSelectLetter = (letter: string) => {
    scrollToSelectedLetter(letter);
  };

  const checkScrollable = () => {
    const container = containerRef.current;
    if (!container) return false;

    return container.scrollHeight > container.clientHeight;
  };

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [showTopArrow, setShowTopArrow] = useState(false);
  const [showBottomArrow, setShowBottomArrow] = useState(true);

  useLayoutEffect(() => {
    setShowScrollButtons(checkScrollable());
  }, []);

  useResizeObserver({
    // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
    ref: containerRef,
    onResize: () => {
      setShowScrollButtons(checkScrollable());
    },
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const handleScroll = () => {
      const container = containerRef.current;
      if (!container) return;

      const scrollTop = container.scrollTop;
      const scrollBottom = container.scrollTop + container.clientHeight;
      const scrollHeight = container.scrollHeight;

      setShowTopArrow(scrollTop !== 0);
      setShowBottomArrow(scrollBottom !== scrollHeight);
    };

    containerRef.current.addEventListener("scroll", handleScroll);
    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      css={{
        position: "relative",
        height: "100%",
      }}
      style={{
        fontSize: `${fontSize}em`,
      }}
    >
      <Arrow
        up
        show={showScrollButtons && showTopArrow}
        scrollElemRef={containerRef}
      />
      <div
        ref={containerRef}
        css={{
          height: "100%",
          maxHeight: "calc(100% - 2em * 2)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          overflow: "auto",
          // hide scrollbar
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {"ABCDEFGHIJKLMNOPQRSTUVWXYZ#".split("").map((letter) => (
          <Letter
            key={letter}
            onClick={() => handleSelectLetter(letter)}
            style={
              selectedLetter === letter
                ? {
                    color: colors.primaryAccentColor,
                  }
                : !(letter in dividerLetterRefs)
                  ? {
                      color: colors.tertiaryTextColor,
                    }
                  : undefined
            }
          >
            {letter}
          </Letter>
        ))}
      </div>
      <Arrow
        down
        show={showScrollButtons && showBottomArrow}
        scrollElemRef={containerRef}
      />
    </div>
  );
};

function Arrow({
  up,
  show,
  scrollElemRef,
}: ({ up: true; down?: never } | { down: true; up?: never }) & {
  show: boolean;
  scrollElemRef: React.RefObject<HTMLElement | null>;
}) {
  return (
    <>
      <Letter
        css={{
          color: colors.primaryTextColor,
          position: "absolute",
          [up ? "top" : "bottom"]: 0,
          left: 0,
          width: "100%",
          transition: "opacity 0.35s ease",
          "&:disabled": {
            color: colors.tertiaryTextColor,
          },
        }}
        style={{
          opacity: show ? 1 : 0,
        }}
        onClick={() => {
          if (!scrollElemRef.current) return;

          const scrollBy = scrollElemRef.current.clientHeight;
          scrollElemRef.current.scrollBy({
            top: up ? -scrollBy : scrollBy,
            behavior: "smooth",
          });
        }}
      >
        {up ? "▲" : "▼"}
      </Letter>
      {/* spacer */}
      <div css={{ height: "2em" }} />
    </>
  );
}

export default AlphabeticalScrollBar;
