import { colors } from "@/styles/global.styles";
import Contact from "@/utils/contacts/contact";
import { contactState } from "@/utils/contacts/contactState";
import { isValidContact } from "@/utils/helpers/Utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import useConfirmNavigation from "@/utils/hooks/useConfirmNavigation";
import { css } from "@emotion/react";
import Modal from "antd/es/modal";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import ContactForm from "../contactListScreen/ContactForm";
import { Button } from "./Button";

export default function ContactModal({
  open,
  handleOk,
  handleCancel,
  numberToCall,
  webgwContact,
  title = "New Contact",
}: {
  open: boolean;
  handleOk: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  numberToCall?: string;
  webgwContact?: WebGwContact | null;
  title?: string;
}) {
  const formState = contactState;
  const contact = useSnapshot(formState);

  const [initialState, setInitialState] = useState(contact);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect, valtio/state-snapshot-rule
      setInitialState(contact);
    }
  }, [open]);

  useEffect(() => {
    // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
    setIsChanged(JSON.stringify(initialState) !== JSON.stringify(contact));
  }, [contact, initialState]);

  useConfirmNavigation(
    "Are you sure you want to leave? Contact information will be lost.",
    // Stop reload if new user creation with valid information
    () => open && !contactState.id && isValidContact(new Contact(contactState))
  );

  const isValid = isValidContact(new Contact(contact));

  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      maskClosable={false}
      onCancel={handleCancel}
      destroyOnClose
      footer={
        <div css={{ display: "flex", gap: "0.75em", justifyContent: "end" }}>
          <Button
            onClick={handleCancel}
            css={[buttonStyles, { backgroundColor: colors.secondaryTextColor }]}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleOk}
            disabled={!isChanged || !isValid}
            css={[
              buttonStyles,
              {
                backgroundColor:
                  isChanged && isValid
                    ? colors.primaryAccentColor
                    : colors.secondaryTextColor,
                cursor: isChanged && isValid ? "pointer" : "not-allowed",
              },
            ]}
          >
            Save
          </Button>
        </div>
      }
      css={{ zIndex: "9999", display: "flex", justifyContent: "center" }}
    >
      <ContactForm
        webgwContact={webgwContact}
        numberInputfromKeypad={numberToCall}
      />
    </Modal>
  );
}

const buttonStyles = css({
  color: colors.primaryTextColor,
  padding: "0.5em",
  borderRadius: "8px",
  maxWidth: "6em",
  minWidth: "3em",
  width: "6vw",
  height: "3em",
});
