import { useEffect } from "react";
import { useBlocker } from "react-router-dom";

const useConfirmNavigation = (
  confirmMessage: string,
  isEnabled: () => boolean
) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEnabled()) {
        event.preventDefault();
        event.returnValue = confirmMessage; // For older browsers
        return confirmMessage; // For modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (blocker.state === "blocked") {
      const confirmLeave = !isEnabled() || window.confirm(confirmMessage);
      if (confirmLeave) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  const confirmNavigation = (enable: boolean, fn: VoidFunction) => {
    if (!enable || window.confirm(confirmMessage)) {
      fn();
    }
  };

  return confirmNavigation;
};

export default useConfirmNavigation;
