import { IconButtonBox } from "@/components/shared/Button";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import ContactModal from "@/components/shared/ContactModal";
import { colors } from "@/styles/global.styles";
import { deleteContact, editContact } from "@/utils/contacts/index";
import { checkPhoneNumberCaps } from "@/utils/helpers/loginAndCaps/capabilities";
import { useToast } from "@/utils/helpers/toastManager";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useBlock } from "@/utils/hooks/useBlock";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AnimatePresence } from "motion/react";
import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useProxy } from "valtio/utils";
import VerseLogo from "../../assets/VerseLogo";
import ContactInfo from "../../components/contacts/ContactInfo";
import {
  DropDownButton,
  DropDownContainer,
} from "../../components/keyPad.style";
import {
  dropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "../../components/shared/Dropdown.style";
import SectionHeader from "../../components/shared/SectionHeader";
import {
  contactState,
  resetContactState,
} from "../../utils/contacts/contactState";
import { Box } from "../layout/Layout.style";

type Props = {
  webgwContact: WebGwContact;
  customHeaderChildren?: React.ReactNode;
} & Parameters<typeof Box>[0];

const UserContactInfoScreen = ({
  webgwContact,
  customHeaderChildren,
  ...boxProps
}: Props) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { showToast } = useToast();
  const [contactBlocked, setContactBlocked] = useState(false);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(dropdownRef, () => closeDropdown(), "mouseup");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    }
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const contact = useProxy(contactState);
  useEffect(() => {
    webgwContact?.refreshCaps();
  }, [webgwContact]);

  const block = useBlock({
    contact: webgwContact,
    forceBlock: contactBlocked,
    onContactBlocked: (blocked: boolean) => setContactBlocked(blocked),
  });

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    await deleteContact(webgwContact?.id, showToast);
    resetContactState();
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleEditOk = async () => {
    if (contactState.phoneNumber[0].number) {
      for (const number of contact.phoneNumber) {
        await checkPhoneNumberCaps(number.number);
      }
    }

    await editContact(contact, showToast);
    setIsEditModalOpen(false);
  };

  const handleEditCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsEditModalOpen(false);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  return (
    <Box {...boxProps}>
      {webgwContact ? (
        <>
          <SectionHeader pageName="Contact Info">
            <div css={{ position: "relative", display: "inline-block" }}>
              <IconButtonBox
                css={{
                  backgroundColor: isDropdownOpen
                    ? colors.secondaryBackground
                    : "transparent",
                }}
                ref={dropdownRef}
                onClick={toggleDropdown}
              >
                <MoreVertIcon />
                {isDropdownOpen && (
                  <DropDownContainer css={[dropdownContainer, headerDropdown]}>
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={handleOpenEditModal}
                    >
                      <EditIcon /> Edit Contact
                    </DropDownButton>
                    {/* <DropDownButton css={footerDropdownItem} disabled>
                    <StarIcon /> Favourites
                </DropDownButton>*/}
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={() => block.show()}
                    >
                      <BlockIcon /> {block.value}
                    </DropDownButton>
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={showDeleteModal}
                    >
                      <DeleteIcon /> Delete Contact
                    </DropDownButton>
                  </DropDownContainer>
                )}
              </IconButtonBox>
            </div>
            {customHeaderChildren}
          </SectionHeader>

          <ContactModal
            title="Edit Contact"
            open={isEditModalOpen}
            handleOk={handleEditOk}
            handleCancel={handleEditCancel}
            webgwContact={webgwContact}
          />

          {block.modal}
          <AnimatePresence>
            {isDeleteModalOpen && (
              <ConfirmationPopup
                title="Delete Contact"
                togglePopup={handleDeleteCancel}
                confirmationMessage="Are you sure you want to delete this contact?"
                primaryButtonText="Delete"
                handleAction={handleDeleteOk}
              />
            )}
          </AnimatePresence>
        </>
      ) : (
        <VerseLogo
          style={{
            display: "flex",
            height: "10rem",
            position: "relative",
            top: "35%",
          }}
        />
      )}
      {webgwContact && (
        <ContactInfo
          webgwContact={webgwContact}
          contactBlocked={contactBlocked}
          onContactBlocked={setContactBlocked}
        />
      )}
    </Box>
  );
};

export default UserContactInfoScreen;
